import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import * as API from "../../../Utils/Services/api";
import translation from "../../Translations/translation.json";
import "./ViewPositions.css";
import { useLanguage } from "../../LanguageContext";
import { toast } from "react-toastify";

const ViewPositions = () => {
  const [selectedVersion, setSelectedVersion] = useState("v2");
  const [liquidityCreated, setLiquidityCreated] = useState([]);
  const { toggleLanguage, language } = useLanguage();

  const translate = (key) => {
    const keys = key.split(".");
    return (
      keys.reduce((obj, keyPart) => obj?.[keyPart], translation[language]) ||
      key
    );
  };

  const [showAllLiquidity, setShowAllLiquidity] = useState(false);
  const showLiquidity = (i) => {
    setShowAllLiquidity((prevIndex) => (prevIndex === i ? null : i));
  };
  const { walletAddress } = useParams();
  const getAllLiquidityDetail = async () => {
    try {
      const resp = await API.ShowAllLiquidityPools(walletAddress);
      const filteredResp = resp.data?.resultList?.filter(
        (item) => Number(item.stakedEthw) > 0
      );
      setLiquidityCreated(filteredResp);
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred");
      setLiquidityCreated(null);
    }
  };
  // console.log("-----", liquidityCreated);

  useEffect(() => {
    getAllLiquidityDetail();
  }, []);
  const handleCopy = (address) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        toast.success("Copied Successfully");
      })
      .catch((err) => {
        toast.error("Error");
      });
  };
  return (
    <div
      className={`section-bg view-positions-pg view-positions-pg-height ${
        liquidityCreated.length > 3 ? "length-more" : "length-less"
      }`}
      style={
        liquidityCreated.length > 2 ? { height: "100%" } : { height: "90vh" }
      }
    >
      <div className="gradient-bg-circle-2"> </div>
      <div className="gradient-bg-circle"></div>
      <div className="container">
        <div className="page-width-vp">
          <div className="vp-pg">
            <div className="view-positions-heading">
              <h2>{translate("viewPositions.heading")}</h2>
              <div className="dropdown">
                <button
                  // className="btn dropdown-btn-pool dropdown-toggle"
                  className="btn dropdown-btn-pool "
                  type="button"
                  // data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedVersion}
                </button>
                {/* <ul className="dropdown-menu">
                  <li>
                    <button className="dropdown-item">v2</button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleVersionChange("v3")}
                    >
                      v3
                    </button>
                  </li>
                </ul> */}
              </div>
            </div>
            {selectedVersion === "v2" ? (
              <div className="navlink-a-btn-migrate">
                <NavLink to="/migrate" className="migrate-btn-a">
                  <button className="migrate-btn">
                    {translate("viewPositions.migrate")}
                    <i className="fa fa-angle-double-right fa-xs"></i>
                  </button>
                </NavLink>
                <NavLink to="/createPool">
                  <button className="new-positions-btn new-positions-relative">
                    <i className="fa fa-plus me-1 fa-xs"></i>{" "}
                    {translate("viewPositions.newposition")}
                  </button>
                </NavLink>
              </div>
            ) : (
              <></>
            )}
          </div>{" "}
          <div className="selected-content">
            {selectedVersion === "v3" ? (
              <p>{translate("viewPositions.descriptionv3")}</p>
            ) : (
              <>
                {liquidityCreated.length > 0 ? (
                  <>
                    <div className="account-analytics">
                      <p>Account analytics</p>
                      {liquidityCreated.map((item, i) => (
                        <div
                          key={i}
                          className="view-liquidi"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="view-created-liquidity">
                            <div className="created-liquidity-img">
                              <img
                                className="created-liquidity-img-mt"
                                src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1736146653/AFH/HomeImage/null_main_1736146653551.jpg"
                                alt=""
                              />
                              <span className=""> {item.tokenName}</span>
                            </div>
                            <div onClick={() => showLiquidity(i)}>
                              <button className="manage-liquidity-btn">
                                {item.stakedEthw}/ {item.stakedTokens}
                                {/* <i className="fa fa-angle-down ms-1"></i> */}
                              </button>
                            </div>
                          </div>
                          {/* {showAllLiquidity === i && ( */}
                          <div className="manage-all-liquidity">
                            <div className="liquidty-info">
                              <div className="liquidity-info-title">
                                Pool address:
                              </div>
                              <div className="liquidity-info-amt">
                                {item.poolAddress.slice(0, 6)}...
                                {item.poolAddress.slice(-5)}
                                <i
                                  className="fa fa-copy ms-1"
                                  onClick={() => handleCopy(item.poolAddress)}
                                ></i>
                              </div>
                            </div>
                            <div className="liquidty-info">
                              <div className="liquidity-info-title">
                                Pooled ETHW:
                              </div>
                              <div className="liquidity-info-amt">
                                {item.stakedEthw}
                                <img
                                  className="ms-1"
                                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1736146653/AFH/HomeImage/null_main_1736146653551.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="liquidty-info">
                              <div className="liquidity-info-title">
                                Pooled tokens:
                              </div>
                              <div className="liquidity-info-amt">
                                {item.stakedTokens}
                                <img
                                  className="ms-1"
                                  src={item.imageUrl}
                                  alt=""
                                />
                              </div>
                            </div>
                            {/* <div className="liquidty-info">
                              <div className="liquidity-info-title">
                                Your pool share
                              </div>
                              <div className="liquidity-info-amt">0.30%</div>
                            </div> */}
                          </div>
                          {/* )} */}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <p style={{ textAlign: "center" }}>
                    {translate("viewPositions.descriptionv2")}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPositions;
